<template>
  <div>
    <section>
      <Breadcrumb
        :title="user.fullname"
        :array="[
          { islink: true, url: '/', text: 'Ana Sayfa' },
          {
            islink: true,
            url: '/terapist/' + user.shortlink,
            text: user.fullname,
          },
          { islink: false, url: '', text: 'Randevu' },
        ]"
      />
    </section>
    <CreateAppointmentSkeleton v-if="loading" />
    <div class="container" v-if="!loading">
      <div class="row mt-5 mb-5">
        <div class="col-sm-12 col-md-8 col-xl-8">
          <div class="card">
            <div class="card-header">
              <h2 class="card-title">Randevu Bilgileri</h2>
            </div>
            <div class="card-body">
              <div
                class="text-center alert alert-primary w-100 mb-5"
                role="alert"
              >
                Randevu oluşturmak ücretsizdir.
              </div>
              <div class="form-group">
                <label class="form-label text-dark">Hizmet Seç *</label>
                <select v-model="selectedExperiences" class="form-control">
                  <option :value="[]" selected disabled>Hizmet Türü Seç</option>
                  <option
                    v-for="(a, i) in experiences"
                    :key="'experience' + i"
                    :value="a"
                  >
                    {{ a.experience_name }}
                  </option>
                </select>
              </div>
              
              <div
                class="form-group"
                v-if="this.selectedExperiences.length != 0"
              >
                <label class="form-label text-dark">Görüşme Yöntemi *</label>
                <select v-model="selectedMethods" class="form-control">
                  <option value="0" selected>Görüşme Yöntemi Seç</option>
                  <option
                    :selected="selectedMethodControl('F2F')"
                    value="1"
                    v-if="selectedExperiences.face_to_face == 1"
                  >
                    Yüz Yüze Görüşme
                  </option>
                  <option
                    value="2"
                    :selected="selectedMethodControl('HOME')"
                    v-if="selectedExperiences.home_service == 1"
                  >
                    Evde Görüşme
                  </option>
                  <option :selected="selectedMethodControl('ONLINE')" value="3" v-if="selectedExperiences.online == 1">
                    Online Görüşme
                  </option>
                </select>
              </div>
              <div
                class="form-group"
                v-if="
                  this.selectedExperiences.length != 0 &&
                  this.selectedExperiences.pre_meeting == 1
                "
              >
                <div
                  class="form-check form-switch mb-2"
                  style="padding-left: 29px"
                  :key="'cb-' + i"
                >
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="checkbox6"
                    id="pre_meeting"
                    @click="preMeeting"
                  />
                  <label class="form-check-label" for="pre_meeting"
                    >Ücretsiz Ön Randevu
                  </label>
                </div>
              </div>
              <div
                class="form-group"
                v-if="this.selectedExperiences.length != 0"
              >
                <label class="form-label text-dark"
                  >Ödeme Yöntemi Seçin *</label
                >
                <select v-model="selectedPaymentMethod" class="form-control">
                  <option :value="[]" selected disabled>
                    Ödeme Yöntemi Seç
                  </option>
                  <option
                    v-for="(a, i) in this.selectedExperiences.payment_methods"
                    :key="'payment_methods' + i"
                    :selected="selectedMethod()"
                    :value="a"
                  >
                    {{ a.title }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label class="form-label text-dark">Ad Soyad *</label>
                <input
                  type="text"
                  class="form-control"
                  name="fullname"
                  id="fullname"
                  v-model="fullname"
                  autocomplete="off"
                  placeholder="Ad Soyad"
                />
              </div>
              <div class="form-group">
                <label class="form-label text-dark">E-Posta *</label>
                <input
                  type="text"
                  v-model="mail"
                  class="form-control"
                  placeholder="abc@xyz.com"
                  name="mail"
                  autocomplete="off"
                  id="mail"
                />
              </div>

              <div class="form-group">
                <label class="form-label text-dark">Telefon *</label>
                <input
                  type="text"
                  v-mask="'0(###) ###-##-##'"
                  v-model="phone"
                  placeholder="0(_ _ _) _ _ _-_ _-_ _"
                  class="form-control"
                  name="phone"
                  autocomplete="off"
                  id="phone"
                />
              </div>
              <div
                class="row"
                v-if="selectedExperiences != null && selectedMethods == 2"
              >
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Adres*</label>
                    <textarea
                      type="text"
                      class="form-control"
                      name="address"
                      autocomplete="off"
                      placeholder="Adres"
                      v-model="address"
                    ></textarea>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label class="form-label">İl*</label>
                    <select
                      name="city"
                      id="city"
                      class="form-control"
                      @change="cityChange"
                    >
                      <optgroup label="Şehir Seç">
                        <option
                          v-for="(item, i) in cityList"
                          :key="i"
                          :value="item.name"
                          :selected="
                            addressList.length > 0 &&
                            addressList[0].city == item.name
                              ? districts(item.districts)
                              : false
                          "
                          :data-province="JSON.stringify(item.districts)"
                        >
                          {{ item.name }}
                        </option>
                      </optgroup>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label class="form-label">İlçe*</label>
                    <select name="province" id="province" class="form-control">
                      <optgroup label="İlçe Seç">
                        <option
                          v-for="(item, i) in selectedDistricts"
                          :key="i"
                          :selected="
                            addressList.length > 0 &&
                            addressList[0].province == item.name
                              ? true
                              : false
                          "
                          :value="item.name"
                        >
                          {{ item.name }}
                        </option>
                      </optgroup>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">Açıklama</label>
                <textarea
                  type="text"
                  class="form-control"
                  name="description"
                  v-model="description"
                  placeholder="İletmeniz gereken bilgiler varsa lütfen bu alanı doldurunuz"
                ></textarea>
              </div>
              <p>
                *Çevrimiçi danışmanlık yöntemlerinde randevuyu iptal
                ya da erteleme taleplerinizi randevu saatinden en az {{ user.appointment_cancel_hour }} saat
                önce bildirme yükümlülüğünüz bulunmaktadır. Gerçekleşmesine {{ user.appointment_cancel_hour }}
                saatten az süre kalmış seanslar için yapılan erteleme ve iptal
                talepleri geçersizdir.
              </p>
              <hr />
              <div class="form-row mb-5">
                <div class="form-check form-check-custom form-check-solid">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="check1"
                    id="check1"
                    value="1"
                  />
                  <label class="form-check-label" for="check1">
                    <router-link
                      to="/page-4-gizlilik-politikasi"
                      target="_blank"
                      >Kişisel Verilerin Korunması Politikası</router-link
                    >'nı Okudum Kabul Ediyorum.
                  </label>
                </div>
              </div>
              <div class="form-row mb-5">
                <div class="form-check form-check-custom form-check-solid">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="check2"
                    id="check2"
                    value="1"
                  />
                  <label class="form-check-label" for="check2">
                    <router-link
                      to="/page-9-kullanim-sozlesmesi"
                      target="_blank"
                      >Kullanım Sözleşmesi</router-link
                    >'ni Okudum Kabul Ediyorum.
                  </label>
                </div>
              </div>
              <button
                type="button"
                id="saveButton"
                class="btn btn-primary w-100"
                @click="save"
              >
                <span class="indicator-label">Devam Et</span>
              </button>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-xl-4">
          <div class="card">
            <div class="card-header">
              <h2 class="card-title">TERAPİST BİLGİLERİ</h2>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-4">
                  <lazy-image
                    :src="
                      'https://storage.terapivitrini.com/' +
                      user.profile_image_url
                    "
                    class="w-150 br-2"
                    alt="user"
                  />
                </div>
                <div class="col-sm-12 col-md-8 col-xl-8">
                  <div class="col-12">{{ user.fullname }}</div>
                  <div class="col-12 mt-1">{{ user.appointment_date }}</div>
                  <div class="col-12 mt-1">
                    <button class="btn btn-primary" @click="apponimentCalendar">
                      Randevu Tarihini Değiştir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="user.id != null">
            <Sidebar
              v-if="calenderVisibility"
              :userid="user.id"
              :selectedDate="$route.params.date"
            />
          </template>
        </div>
      </div>
    </div>
    <!--SMS MODAL-->
    <SmsModal @response="handleData($event)" :phone="phone" />
    <PaymentModal
      @response="paymentData($event)"
      :selectedExperiences="selectedExperiences"
      :currentAppointmentProgress="currentAppointmentProgress"
    />
  </div>
</template>
<script>
import Sidebar from "../ProfileDetail/Components/Sidebar.vue";
import PaymentModal from "./Components/PaymentModal.vue";
import SmsModal from "./Components/SmsModal.vue";
import CreateAppointmentSkeleton from "./Components/CreateAppointmentSkeleton.vue";
import Breadcrumb from "../../components/Breadcrumb.vue";
export default {
  metaInfo: {
    meta: [
      { name: "robots", content: "noindex" },
      { name: "googlebot", content: "noindex" },
    ],
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    document.title = "Randevu Oluştur";
    const meta = document.getElementsByTagName("meta");
    meta.description.content =
      "Terapi Vitrini - İhtiyacınız olan terapiyi ve terapisti bulun";
    this.process();
    if (this.$store.state.isLogin) {
      this.$store.dispatch("addressListGet").then((value) => {
        this.addressList = value;
        this.address = this.addressList[0].address;
      });
      this.$store.dispatch("phoneListGet").then((value) => {
        this.phoneList = value;
        this.phone = this.phoneList[0].phone;
      });
    }
  },
  data() {
    return {
      phoneList: [],
      addressList: [],
      user: [],
      pre_meeting: 0,
      experiences: [],
      selectedMethods: 0,
      selectedExperiences: [],
      selectedPaymentMethod: [],
      mail: "",
      loading: true,
      fullname: "",
      address: "",
      calenderVisibility: false,
      phone: "",
      calenderToggleBool: false,
      description: "",
      cityList: [],
      currentAppointmentProgress: [],
      selectedDistricts: [],
      checkReturn: false,
      phoneRegex: new RegExp(
        "^[+]*[0]*[ ]{0}[(][5]{1}[0-9]{1,3}[ ]{0,1}[)]{0,1}[ ]{0,1}[0-9]{1,3}[ ]{0,1}[0-9]{2}[-]{0,1}[0-9]{2}[]{0,1}[-./]{0,1}[-]{0,1}[0-9]{1,5}$"
      ),
      emailRegex: new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}"),
    };
  },
  watch: {
    "$route.params.date": function (e) {
      this.process();
      this.$vToastify.success("Tarih değiştirildi", "Başarılı");
    },
    checkReturn: function () {
      if (this.checkReturn) {
        /**TELEFON ONAYLANDI */
        this.$modal.hide("smsmodal");
        /**BİLGİLERİ KAYDET */
        let city = "";
        let province = "";
        if (this.selectedExperiences != null && this.selectedMethods == 2) {
          city = document.querySelector("select[name=city]").value;
          province = document.querySelector("select[name=province]").value;
        }
        let appointment = {
          fullname: this.fullname,
          address: this.address,
          phone: this.phone,
          mail: this.mail,
          selected_experience_id: this.selectedExperiences.experience_id,
          selected_methods: this.selectedMethods,
          selected_payment_methods: this.selectedPaymentMethod.id,
          city: city,
          province: province,
          description: this.description,
          appointment_hour_id: this.user.appointment_hour_id,
          user_id: this.user.id,
          pre_meeting: this.pre_meeting,
          member_id: this.$store.state.user_id,
        };
        if (
          this.selectedPaymentMethod.is_online == 1 &&
          this.pre_meeting == 0
        ) {
          if (this.$store.state.isLogin) {
            //ÖDEME YAPTIR
            this.currentAppointmentProgress = appointment;
            this.process();
            $("#paymentmodal").modal("show");
          } else {
            this.$vToastify.warning(
              "Online ödeme yapabilmek için giriş yapmanız gerekmektedir.",
              "Uyarı!"
            );
          }
        } else {
          this.$store.dispatch("appointmentSave", appointment).then((value) => {
            this.$vToastify.success(
              "Randevu başarılı bir şekilde kaydedildi",
              "Başarılı!"
            );
            this.$router.push("/terapist/" + this.user.shortlink);
          });
        }
      }
    },
  },
  methods: {
    selectedMethod(){
      if(this.selectedExperiences.payment_methods.length==1)
      {
        this.selectedPaymentMethod=this.selectedExperiences.payment_methods[0];
        return true;
      }else {
        return false;
      }
        
    },
    selectedMethodControl(type){
      if(type=='F2F'){
        if(this.selectedExperiences.face_to_face==1 && this.selectedExperiences.online==0 && this.selectedExperiences.home_service==0){
          this.selectedMethods=1
          return true;
        }
      }
      if(type=='ONLINE'){
        if(this.selectedExperiences.face_to_face==0 && this.selectedExperiences.online==1 && this.selectedExperiences.home_service==0){
          this.selectedMethods=3
          return true;
        }
      }
      if(type=='HOME'){
        if(this.selectedExperiences.face_to_face==0 && this.selectedExperiences.online==0 && this.selectedExperiences.home_service==1){
          this.selectedMethods=2
          return true;
        }
      }
      return false
    },
    preMeeting() {
      if (this.pre_meeting == 0) {
        //ÖN RANDEVUYU ALABİLİR Mİ
        this.$store
          .dispatch("preMeetingCheck", {
            selectedMethods: this.selectedExperiences.experience_id,
            expert_id: this.user.id,
          })
          .then((e) => {
            if (e.type == "success") {
              this.pre_meeting = 1;
            } else {
              this.$vToastify.warning(
                "Daha önce ön randevu oluşturmuşsunuz!",
                "Uyarı!"
              );
              document.getElementById("pre_meeting").checked = false;
            }
          })
          .catch((e) => {
            this.$vToastify.warning(
              "Üyelik olmadan ön randevu talepleri oluşturulamaz",
              "Uyarı!"
            );
            document.getElementById("pre_meeting").checked = false;
          });
      } else {
        this.pre_meeting = 0;
        document.getElementById("pre_meeting").checked = false;
      }
    },
    apponimentCalendar() {
      if (this.calenderVisibility == false) {
        this.calenderVisibility = true;
      } else {
        this.calenderVisibility = false;
      }
    },
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
    districts(item) {
      this.selectedDistricts = item;
      return true;
    },
    calenderToggle() {
      if (this.calenderToggleBool) {
        this.calenderToggleBool = false;
      } else {
        this.calenderToggleBool = true;
      }
    },
    process() {
      let appointmentControl = {
        username: this.$route.params.username,
        date: this.$route.params.date,
      };
      this.$store
        .dispatch("appointmentControl", appointmentControl)
        .then((value) => {
          if (value.type == "success") {
            this.user = value.user;
            if (this.cityList.length == 0) {
              this.cityList = value.user.city;
              this.selectedDistricts = value.user.city[0].districts;
            }
            this.experiences = value.experiences;
            this.mail = this.$store.state.email;
            this.fullname = this.$store.state.fullname;
            this.loading = false;
            if (
              localStorage.getItem("token") &&
              localStorage.getItem("token") != null &&
              this.$store.state.isLogin == true
            ) {
              if (this.$store.state.user_id == this.user.id) {
                this.$vToastify.error(
                  "Kendinize randevu oluşturamazsınız",
                  "Hata!"
                );
                this.$router.push("/terapist/" + this.user.shortlink);
              }
            }
          } else {
            if (value.message == "ERRxUDF") {
              this.$vToastify.warning("Hatalı tarih formatı", "Uyarı!");
              setTimeout(() => {
                this.$router.go(-1);
              }, 1500);
            }
            if (value.message == "ERRxHNF") {
              this.$vToastify.warning("Saat dolu yada zaman geçmiş", "Uyarı!");
              setTimeout(() => {
                this.$router.go(-1);
              }, 1500);
            }
            if (value.message == "ERRxDEX") {
              this.$vToastify.warning(
                "Randevu süresi geçmişten bir gün olamaz",
                "Uyarı!"
              );
              setTimeout(() => {
                this.$router.go(-1);
              }, 1500);
            }
            if (value.message == "ERRxDNF") {
              this.$vToastify.warning("Randevu tarihi bulunamadı", "Uyarı!");
              setTimeout(() => {
                this.$router.go(-1);
              }, 1500);
            }
            if (value.message == "ERRxNYSAC") {
              this.$vToastify.error(
                "Kendinize randevu oluşturamazsınız",
                "Hata!"
              );
              tsetTimeout(() => {
                this.$router.go(-1);
              }, 1500);
            }
          }
        });
    },
    cityChange() {
      var element = document.getElementById("city");
      var option_doj =
        element.options[element.selectedIndex].getAttribute("data-province");
      this.selectedDistricts = JSON.parse(option_doj);
    },
    handleData: function (e) {
      this.checkReturn = e;
    },
    paymentData: function (e) {
      this.checkReturn = e;
    },
    save() {
      this.process();
      let fullname = document.querySelector("input[name=fullname]").value;
      let address = "";
      let city = "";
      let province = "";
      if (this.selectedExperiences != null && this.selectedMethods == 2) {
        address = document.querySelector("textarea[name=address]").value;
        city = document.querySelector("select[name=city]").value;
        province = document.querySelector("select[name=province]").value;
      }
      let check1 = document.querySelector("input[name=check1]").checked;
      let check2 = document.querySelector("input[name=check2]").checked;
      let number = this.phone;
      if (fullname == null || fullname == "") {
        this.$vToastify.warning("Ad Soyad Girilmedi", "Uyarı!");
      } else if (
        this.selectedExperiences == null ||
        this.selectedExperiences.length == 0
      ) {
        this.$vToastify.warning("Hizmet türü seçmediniz", "Uyarı!");
      } else if (this.selectedPaymentMethod == null) {
        this.$vToastify.warning("Ödeme yöntemi seçmediniz", "Uyarı!");
      } else if (this.selectedMethods == 0) {
        this.$vToastify.warning("Görüşme yöntemi seçilmedi", "Uyarı!");
      } else if (number == null || number == "") {
        this.$vToastify.warning("Telefon numarası girilmedi", "Uyarı!");
      } else if (!number.match(this.phoneRegex)) {
        this.$vToastify.warning("Telefon numarası hatalı", "Uyarı!");
      } else if (this.mail == "" || this.mail == null) {
        this.$vToastify.warning("E-Posta adresi girilmedi", "Uyarı!");
      } else if (!this.mail.match(this.emailRegex)) {
        this.$vToastify.warning("E-Posta adresi hatalı", "Uyarı!");
      } else if (
        this.selectedExperiences != null &&
        this.selectedMethods == 2 &&
        (address == null || address == "")
      ) {
        this.$vToastify.warning("Adres girilmedi", "Uyarı!");
      } else if (
        this.selectedExperiences != null &&
        this.selectedMethods == 2 &&
        (city == null || city == "")
      ) {
        this.$vToastify.warning("Şehir seçilmedi", "Uyarı!");
      } else if (
        this.selectedExperiences != null &&
        this.selectedMethods == 2 &&
        (province == null || province == "")
      ) {
        this.$vToastify.warning("İlçe seçilmedi", "Uyarı!");
      } else if (!check1) {
        this.$vToastify.warning(
          "Kişisel Verilerin Korunması Politikası kabul edilmedi",
          "Uyarı!"
        );
      } else if (!check2) {
        this.$vToastify.warning("Kullanım Sözleşmesi kabul edilmedi", "Uyarı!");
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML =
          '<span class="indicator-progress">Lütfen bekleyiniz...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        /**SMS DOĞRULAMA KODU İSTE */
        let phoneCheck = {
          phone: this.phone,
        };
        this.$store.dispatch("smsCheck", phoneCheck).then((value) => {
          if (value.type == "success") {
            /**SMS GÖNDERİLDİ */
            this.$modal.show("smsmodal");
          }
        });
      }
    },
  },
  components: {
    Sidebar,
    SmsModal,
    CreateAppointmentSkeleton,
    Breadcrumb,
    PaymentModal,
  },
  mounted() {
    this.backToTop();
  },
};
</script>