<template>
  <modal
    name="smsmodal"
    :width="360"
    :height="250"
    :adaptive="false"
    :clickToClose="false"
  >
    <div class="m-5 text-center">
      <h3>SMS KODUNU GİRİNİZ</h3>
      <input
        class="form-control"
        type="text"
        v-mask="'X X X X X X'"
        v-model="code"
      />
      <button
        class="btn btn-primary mt-5 w-100"
        type="button"
        @click="check"
        value="ONAYLA"
      >ONAYLA</button>
      <input
        class="btn btn-primary mt-5 w-100"
        type="button"
        @click="reSend"
        value="Yeniden Gönder"
      />
    </div>
  </modal>
</template>
<script>
export default {
  created() {},
  props: ["phone"],
  data() {
    return {
      code: "",
    };
  },
  methods: {
    reSend() {
      this.$store.dispatch("smsCheck", {"phone":this.phone}).then((value) => {
        if (value.type == "success") {
          /**SMS GÖNDERİLDİ */
          this.$vToastify.success("Yeni Sms Gönderildi", "Başarılı");
        } else {
          if (value.message == "ERRxMRPE") {
            this.$vToastify.warning("5dk içerisinde en fazla 3 SMS isteğinde bulunabilirsiniz!", "Uyarı");
          }
        }
      });
    },
    check() {
      /**CODE DOĞRULA */
      let info = {
        phone: this.phone,
        code: this.code,
      };
      this.$store.dispatch("smsCodeCheck", info).then((value) => {
        if (value.type == "success") {
          this.$emit("response", true);
        } else {
          if (value.message == "") {
            this.$vToastify.warning("Kodun Süresi Geçmiş", "Uyarı");
          }
          if (value.message == "ERRxMTO") {
            this.$vToastify.warning("Kodun Yanlış", "Uyarı");
          }
        }
      });
    },
  },
};
</script>